import React from "react"
import { QuickServicesModuleContainer } from "./style"
import { SlantedTeaser } from "../SlantedTeaser"
import { FlexGrid, FlexRow, FlexCol } from "components/FlexGrid"
import { ReactComponent as Arrow } from "images/arrow.svg"

const QuickServicesModule = ({ data }) => {
  const arrow = <Arrow />
  return (
    <QuickServicesModuleContainer>
      <h2>Discover our services:</h2>
      <FlexGrid fullWidth rowGap={[15, 0]}>
        <FlexRow>
          {data.map((teaser, index) => {
            if (!teaser) return null

            return (
              <FlexCol key={index} xs={6} sm={4}>
                <SlantedTeaser
                  title={teaser.title}
                  excerpt={
                    teaser.pageSettings?.homePageFeaturedTeaserExcerpt ||
                    teaser.excerpt
                  }
                  img={{
                    ...teaser.featuredImage,
                    sizes: "(min-width: 768px) 260px, 50vw",
                  }}
                  button={{ icon: arrow, noBackground: true }}
                  internalLink
                  link={teaser.link}
                />
              </FlexCol>
            )
          })}
        </FlexRow>
      </FlexGrid>
    </QuickServicesModuleContainer>
  )
}

export default QuickServicesModule
