import styled from "styled-components"
import { device } from "breakpoints"
import { Teaser } from "components/Teaser"
import {
  ImageContainer,
  Title,
  Excerpt,
  InfoContainer,
  TeaserButton,
} from "components/Teaser/style"

export const SlantedTeaserContainer = styled(Teaser)`
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background: white;
  overflow: hidden;
  padding-top: 123.8%;

  @media ${device.large} {
    :hover {
      ${Excerpt} {
        opacity: 1;
        transition-delay: 160ms;
      }
      ${ImageContainer} {
        transform: translateY(80px);
      }

      ${TeaserButton} {
        opacity: 1;
      }
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    :hover {
      ${Excerpt} {
        opacity: 0;
      }
      ${ImageContainer} {
        transform: translateY(0px);
      }

      ${TeaserButton} {
        opacity: 0;
      }
    }
  }

  ${Title} {
    font-size: 20px;
    max-width: 130px;
    /* position: absolute; */
    @media ${device.mobile} {
      max-width: 110px;
      font-size: 24px;
    }

    @media ${device.tablet} {
      font-size: 20px;
    }

    @media ${device.desktop} {
      font-size: 24px;
    }
  }

  ${Excerpt} {
    display: none;
    transition: 400ms;
    transition-delay: 0ms;
    cursor: pointer;
    color: #2a3449;
    font-size: 14px;
    opacity: 0;
    margin-bottom: 0;

    @media ${device.large} {
      display: block;
      font-size: 16px;
      margin-top: 15px;
    }
  }

  ${InfoContainer} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    padding: 20px 19px;
    @media ${device.desktop} {
      padding: 32px 30px;
      padding-bottom: 130px;
    }
  }
  ${ImageContainer} {
    order: 2;
    margin: 0;
    z-index: 2;
    /* padding-top: 70%; */
    height: 65%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: white;
    transform: translateY(0);
    transition: 400ms;
    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0 100%);

    @media ${device.desktop} {
      clip-path: polygon(0 19%, 100% 0, 100% 100%, 0 100%);
    }
  }

  ${TeaserButton} {
    position: absolute;
    top: 22px;
    right: 20px;
    display: none;
    padding: 0;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    opacity: 0;
    transition: 140ms;
    @media ${device.desktop} {
      right: 24px;
      top: 31px;
      display: inline-flex;
    }
    .icon {
      margin: 0;
      width: 9px;
    }
  }

  a {
    &:before {
      z-index: 3;
    }
  }
`
