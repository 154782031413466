import React from "react"
import useMedia from "hooks/useMedia.js"
import { NewsButton } from "../customButtons"
import { useStaticQuery, graphql } from "gatsby"
import { Container, MainTitle, NewTeaser, NewCarousel } from "./style"

const TeaserNews = () => {
  const data = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          posts(first: 3) {
            nodes {
              title
              excerpt
              link
              slug
              featuredImage {
                sourceUrl
                srcSet
                mediaDetails {
                  height
                  width
                }
              }
              categories {
                nodes {
                  name
                }
              }
            }
          }
        }
      }
    `
  )

  const isDesktop = useMedia(
    // Media queries
    ["(min-width: 1024px)"],
    //options
    [true],
    // default
    false
  )

  const posts = data?.wpgraphql?.posts?.nodes
  return (
    <Container>
      <MainTitle>Our latest news</MainTitle>
      <NewCarousel
        loop={!isDesktop}
        slidesToShow={[1, 3]}
        draggable={!isDesktop}
        gap={40}
      >
        {posts.map((teaser, index) => {
          return (
            <NewTeaser
              key={index}
              title={teaser.title}
              tag={teaser.categories?.nodes[0]?.name}
              excerpt={teaser.excerpt}
              img={teaser.featuredImage}
              centered
              link={teaser.link}
            />
          )
        })}
      </NewCarousel>
      <NewsButton link="/expert-news-views/" label={"View all news and views"} />
    </Container>
  )
}

export default TeaserNews
