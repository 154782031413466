import React from "react"
import {
  LyfeHomeModuleContainer,
  TitleSection,
  LyfeDropImg,
  TextSection,
} from "./style"
import { ReactComponent as LyfeLogo } from "images/lyfe-logo.svg"
import { useStaticQuery, graphql } from "gatsby"
import { FlexRow, FlexCol } from "components/FlexGrid"
import { Link } from "gatsby"

const LyfeHomeModule = ({ data }) => {
  const dropOfLyfe = useStaticQuery(
    graphql`
      query lyfeImage {
        file(relativePath: { eq: "lyfe-symbol-large.png" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )
  return (
    <LyfeHomeModuleContainer gap={[0, 0, 40]} rowGap={[0, 0, 0]}>
      <FlexRow>
        <FlexCol xs={12} md={4}>
          <TitleSection>
            <LyfeLogo />
            <h2>{data.lHeader}</h2>
          </TitleSection>
        </FlexCol>
        <FlexCol xs={12} md={4}>
          <LyfeDropImg fluid={dropOfLyfe.file.childImageSharp.fluid} />
        </FlexCol>
        <FlexCol xs={12} md={4}>
          <TextSection>
            <p>{data.lDescription}</p>

            <Link to={data.lLink} className="underline">
              Find out more
            </Link>
          </TextSection>
        </FlexCol>
      </FlexRow>
    </LyfeHomeModuleContainer>
  )
}

export default LyfeHomeModule
