import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"

export const QuickServicesModuleContainer = styled(MainColumn)`
  margin-top: -110px;
  margin-bottom: 50px;
  z-index: 3;

  > div {
    z-index: 2;
  }
  @media ${device.tablet} {
    margin-bottom: 70px;
  }
  @media ${device.desktop} {
    margin-top: -206px;
    margin-bottom: 100px;
  }

  @media ${device.large} {
    margin-top: -244px;
  }

  @media (min-width: 1660px) {
    margin-top: -266px;
  }

  h2 {
    font-size: 14px;
    color: white;
    margin-bottom: 35px;
    font-weight: 400;
    z-index: 1;
    @media ${device.desktop} {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }
`
