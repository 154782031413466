import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { TeaserNews } from "../components/TeaserNews"
import { LyfeHomeModule } from "../components/LyfeHomeModule"
import { TestimonialHome } from "../components/TestimonialModule"
import { Stats } from "../components/Stats"
import { QuickServicesModule } from "components/QuickServicesModule"
import { useStaticQuery, graphql } from "gatsby"

const IndexPage = ({ pageContext }) => {
  const {
    LyfeSection,
    homeServices,
    testimonialSection,
    statsModule,
    featuredImage,
    headerOptions,
    previewSQ,
    avTestimonials,
    seo,
  } = pageContext

  const data = useStaticQuery(
    graphql`
      {
        wpgraphql {
          siteOptions {
            siteSettings {
              phoneNumber

              address
            }
          }
        }
      }
    `
  )

  return (
    <div>
      <Layout
        home
        headerImg={featuredImage}
        headerTitle={headerOptions.headerTitle}
        headerText={headerOptions.headerDescription}
        preview={previewSQ}
        stickyNavItems={homeServices.serviceItems}
      >
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
        
          {
            "@context": "https://schema.org",
            "@type": "FinancialService",
            "image": "${featuredImage.sourceUrl}",
            "name": "Johnston Grocke",
            "address": "${data.wpgraphql.siteOptions.siteSettings.address}",
            "telephone": "${data.wpgraphql.siteOptions.siteSettings.phoneNumber}"
          }
        `,
          }}
        ></script>

        <SEO
          title={seo.title}
          description={seo.metaDesc}
          image={featuredImage?.sourceUrl}
        />
        {homeServices && (
          <QuickServicesModule data={homeServices.serviceItems} />
        )}
        {LyfeSection && LyfeSection.lLink && (
          <LyfeHomeModule data={LyfeSection} />
        )}
        <TeaserNews />
        {testimonialSection && testimonialSection.tPrimaryQuote && (
          <TestimonialHome
            data={testimonialSection}
            avTestimonials={avTestimonials}
          />
        )}
        {statsModule && <Stats data={statsModule} />}
      </Layout>
    </div>
  )
}

export default IndexPage
