import styled from "styled-components"
import { device } from "breakpoints"
import { FlexGrid } from "components/FlexGrid"
import AvenueImg from "components/AvenueImg"

export const LyfeHomeModuleContainer = styled(FlexGrid)`
  padding-bottom: 40px;
  /* border-bottom: 1px solid rgba(220, 224, 231, 1); */
  overflow: hidden;
  margin-top: 30px;
  position: relative;
  margin-bottom: 45px;
  @media ${device.tablet} {
    margin-top: 50px;
    padding-bottom: 70px;
  }

  @media ${device.desktop} {
    border-bottom: none;
    padding-bottom: 00px;
    margin-bottom: 0;
    margin-top: 40px;
  }
`

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 30px;
  @media ${device.desktop} {
    align-items: flex-start;
    padding-top: 40px;
  }

  svg {
    height: 32px;
    width: 75px;

    @media ${device.desktop} {
      height: 42px;
      width: 98px;
    }
  }

  h2 {
    text-align: center;
    font-size: 42px;
    width: 100%;
    padding-top: 20px;
    border-top: 1px solid rgba(220, 224, 231, 1);
    margin-top: 25px;
    font-weight: 300;

    @media ${device.desktop} {
      border: none;
      margin-top: 40px;
      padding-top: 30px;
      /* width: 110%; */
      text-align: left;
      font-size: 50px;
      letter-spacing: -2.5px;
      margin-left: -2px;
    }

    @media ${device.large} {
      padding-top: 55px;
      font-size: 60px;
    }
  }
`

export const LyfeDropImg = styled(AvenueImg)`
  width: 134px;
  margin: 0 auto;
  margin-bottom: 30px;
  position: relative;
  overflow: visible !important;
  @media ${device.desktop} {
    width: 100%;
    max-width: 360px;

    img {
      object-fit: contain !important;
    }
  }

  :before {
    content: "";
    height: 1px;
    width: 450px;
    display: none;
    background: rgba(220, 224, 231, 1);
    position: absolute;
    left: 0;
    top: 122px;
    transform: translateX(-101%);

    @media ${device.desktop} {
      display: block;
    }
    @media ${device.large} {
      transform: translateX(-97%);
    }
  }

  :after {
    content: "";
    height: 1px;
    display: none;
    width: 450px;
    background: rgba(220, 224, 231, 1);
    position: absolute;
    right: 0;
    top: 122px;
    transform: translateX(101%);
    @media ${device.desktop} {
      display: block;
    }

    @media ${device.large} {
      transform: translateX(97%);
    }
  }
`

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  font-size: 16px;
  max-width: 670px;
  margin: 0 auto;

  @media ${device.desktop} {
    font-size: 16px;
    text-align: left;
    padding-left: 16px;
    padding-top: 158px;
  }

  @media ${device.large} {
    padding-top: 184px;
    font-size: 18px;
  }
  p {
    margin-bottom: 26px;
    width: 100%;
  }

  a {
    font-weight: 600;
    margin: 0 auto;
    line-height: 110%;
    color: ${props => props.theme.navy};

    @media ${device.desktop} {
      margin: 0;
    }

    :after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
`
